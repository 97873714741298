<template>
    <div class="concept">
        <BannerInnerPage />

        <div class="container concept__breads">
            <Breads :links="breadLinks" />
        </div>

        <div class="concept__content">
            <div class="container">
                <SectionTitle :title="$t('pages.Concept.title')" :hasButton="false"/>
                <div class="concept__html" v-for="(cont, index) in concept" :key="index" v-html="cont.value"></div>
                <Preloader v-if="preloader" />
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>

import Footer from "@/components/Footer"
import BannerInnerPage from "@/components/BannerInnerPage"
import SectionTitle from "@/components/SectionTitle"

export default {
    components: {
        Footer,
        BannerInnerPage,
        SectionTitle,
    },

    data() {
        return {
            concept: null,
            breadLinks: [
                {
                    route: 'Concept',
                    text: this.$i18n.t('pages.Concept.title')
                },
            ],
            
            preloader: true
        }
    },

    async mounted() {
        await this.getConcept()
    },

    watch: {
        $route() {
            this.getConcept()
        }
    },

    methods: {
        getConcept() {
            return this.api.global.concept().then(r => {
                this.concept = r.data
            }).finally(() => {
                this.preloader = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .concept {

        &__breads {
            padding-top: 30px;
        }

        &__content {
            padding: 70px 0;
            min-height: 35vh;
        }

        &__html {
            margin-top: 30px;

            /* img in html-contnent */
            ::v-deep {
                img {
                    margin: 10px;
                }
            }
        }
    }
</style>