<template>
    <div class="banner-inner-page">
        <div class="container" :class="{ 'container--noPadding' : isMobile }">
            <Header :hasLogo="false" />
            <div class="banner-inner-page__content">
                <img class="banner-inner-page__bannerImg" src="~@/assets/img/main_round.png" alt="png">
                <h2 class="typography typography--h2 banner-inner-page__bannerTitle">{{ $t('components.BannerInnerPage.title') }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/components/Header"

export default {    
    components: {
        Header
    },
}
</script>

<style lang="scss" scoped>
    .banner-inner-page {
        background: url('~@/assets/img/banner_inner_page.png') no-repeat center/cover;

        &__content {
            display: flex;
            align-items: flex-end;
            padding: 20px 0 30px;

            @media screen and (max-width: $lg) {
                padding: 90px 20px 30px;
                width: 100%;
            }
        }

        &__bannerImg {
            width: 210px;
            margin-right: 20px;

             @media screen and (max-width: $lg) {
                width: 80px;
            }
        }

        &__bannerTitle {
            color: white;
            font-size: calc(2vw + 2vh);
            font-family: "Mistral";
            font-weight: normal;
        }
    }
</style>